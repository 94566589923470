var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            _vm.checkValidation() && _vm.login()
          }
        }
      },
      [
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _c("div", { staticClass: "col-sm-12 col-md-6" }, [
            _c("div", { staticClass: "form-wrapper" }, [
              _vm._m(1),
              _c(
                "form",
                {
                  attrs: { novalidate: "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      class: {
                        warn: _vm.$v.form.userName.$error,
                        invalid: this.errorMessage,
                        shake: this.shake
                      }
                    },
                    [
                      _c("i", { staticClass: "input-icon fa fa-user" }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.form.userName.$model,
                            expression: "$v.form.userName.$model"
                          },
                          { name: "focus", rawName: "v-focus" }
                        ],
                        staticClass: "form-control-lg form-control input",
                        attrs: {
                          id: "username",
                          type: "text",
                          placeholder: "Username"
                        },
                        domProps: { value: _vm.$v.form.userName.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.$v.form.userName,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm.$v.form.userName.$dirty
                        ? _c("span", [
                            !_vm.$v.form.userName.required
                              ? _c(
                                  "small",
                                  {
                                    staticClass: "form-text text-warning small"
                                  },
                                  [_vm._v("A username is required")]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      class: {
                        warn: _vm.$v.form.password.$error,
                        invalid: this.errorMessage,
                        shake: this.shake
                      }
                    },
                    [
                      _c("i", { staticClass: "input-icon fa fa-lock" }),
                      (_vm.passwordToggled ? "text" : "password") === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.form.password.$model,
                                expression: "$v.form.password.$model"
                              }
                            ],
                            staticClass: "form-control-lg form-control input",
                            attrs: {
                              id: "password",
                              placeholder: "Password",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.$v.form.password.$model
                              )
                                ? _vm._i(_vm.$v.form.password.$model, null) > -1
                                : _vm.$v.form.password.$model
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.$v.form.password.$model,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.$v.form.password,
                                        "$model",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.$v.form.password,
                                        "$model",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.$v.form.password, "$model", $$c)
                                }
                              }
                            }
                          })
                        : (_vm.passwordToggled ? "text" : "password") ===
                          "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.form.password.$model,
                                expression: "$v.form.password.$model"
                              }
                            ],
                            staticClass: "form-control-lg form-control input",
                            attrs: {
                              id: "password",
                              placeholder: "Password",
                              type: "radio"
                            },
                            domProps: {
                              checked: _vm._q(_vm.$v.form.password.$model, null)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(
                                  _vm.$v.form.password,
                                  "$model",
                                  null
                                )
                              }
                            }
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.form.password.$model,
                                expression: "$v.form.password.$model"
                              }
                            ],
                            staticClass: "form-control-lg form-control input",
                            attrs: {
                              id: "password",
                              placeholder: "Password",
                              type: _vm.passwordToggled ? "text" : "password"
                            },
                            domProps: { value: _vm.$v.form.password.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.$v.form.password,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                      _vm.$v.form.password.$dirty
                        ? _c("span", [
                            !_vm.$v.form.password.required
                              ? _c(
                                  "small",
                                  {
                                    staticClass: "form-text text-warning small"
                                  },
                                  [_vm._v("A password is required")]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm.errorMessage
                    ? _c("div", { staticClass: "forgot-pwd mb-2" }, [
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.errorMessage))
                        ])
                      ])
                    : _vm._e(),
                  _vm.accountLockedMessage
                    ? _c("div", { staticClass: "forgot-pwd mb-2" }, [
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(" " + _vm._s(_vm.accountLockedMessage) + " "),
                          _c("i", {
                            staticClass: "fa fa-lock lock-font",
                            attrs: { "aria-hidden": "true" }
                          })
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v("Log in")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link",
                      attrs: { type: "button" },
                      on: { click: _vm.forgotPassword }
                    },
                    [_vm._v("Forgot Password")]
                  )
                ]
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-6 px-50 py-70 d-none d-md-block pt-38 pr-4" },
      [
        _c("img", {
          attrs: { src: "/img/CorvidLogo/New/PAYGATE LOGO_CMYK.svg" }
        }),
        _c("h5", { staticClass: "text-muted" }, [
          _vm._v(
            "Please log in to access your gateway to ultra-secure payments"
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center mb-20" }, [
      _c("img", {
        staticClass:
          "mx-auto d-xs-block d-sm-block d-md-none d-lg-none d-xl-none",
        staticStyle: { "max-width": "136.76px", "margin-bottom": "20px" },
        attrs: { src: "/img/CorvidLogo/New/PAYGATE LOGO_CMYK_P.svg" }
      }),
      _c("h2", { staticClass: "mb-20" }, [_vm._v("Login")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }