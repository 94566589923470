<template>
  <div>
    <form novalidate @submit.prevent="checkValidation() && login()">
      <div class="row">
        <div class="col-md-6 px-50 py-70 d-none d-md-block pt-38 pr-4">
          <img src="/img/CorvidLogo/New/PAYGATE LOGO_CMYK.svg" />
          <h5 class="text-muted">Please log in to access your gateway to ultra-secure payments</h5>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="form-wrapper">
            <div class="text-center mb-20">
              <img
                class="mx-auto d-xs-block d-sm-block d-md-none d-lg-none d-xl-none"
                style="max-width: 136.76px;margin-bottom:20px;"
                src="/img/CorvidLogo/New/PAYGATE LOGO_CMYK_P.svg"
              />

              <h2 class="mb-20">Login</h2>
            </div>
            <form novalidate v-on:submit.prevent>
              <!-- Username/Email -->
              <div
                class="form-group"
                :class="{'warn': $v.form.userName.$error, 'invalid' : this.errorMessage,shake: this.shake }"
              >
                <i class="input-icon fa fa-user"></i>
                <input
                  v-model="$v.form.userName.$model"
                  id="username"
                  type="text"
                  class="form-control-lg form-control input"
                  placeholder="Username"
                  v-focus
                />

                <!-- Validation Messages -->
                <span v-if="$v.form.userName.$dirty">
                  <small
                    class="form-text text-warning small"
                    v-if="!$v.form.userName.required"
                  >A username is required</small>
                </span>
              </div>
              <!-- Password -->
              <div
                class="form-group"
                :class="{'warn': $v.form.password.$error, 'invalid' : this.errorMessage, shake: this.shake }"
              >
                <i class="input-icon fa fa-lock"></i>
                <input
                  v-model="$v.form.password.$model"
                  id="password"
                  :type="passwordToggled? 'text': 'password'"
                  class="form-control-lg form-control input"
                  placeholder="Password"
                />
                <!-- Validation Messages -->
                <span v-if="$v.form.password.$dirty">
                  <small
                    class="form-text text-warning small"
                    v-if="!$v.form.password.required"
                  >A password is required</small>
                </span>
              </div>
              <div class="forgot-pwd mb-2" v-if="errorMessage">
                <span class="text-danger">{{errorMessage}}</span>
              </div>
              <div class="forgot-pwd mb-2" v-if="accountLockedMessage">
                <span class="text-danger">
                  {{accountLockedMessage}}
                  <i class="fa fa-lock lock-font" aria-hidden="true"></i>
                </span>
              </div>
              <button class="btn btn-primary" @click="submit" type="submit">Log in</button>
              <button class="btn btn-link" @click="forgotPassword" type="button">Forgot Password</button>
            </form>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>

import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'login',
  props: ['query'],
  data: function () {
    return {
      form: {
        userName: this.query.username,
        password: this.query.password
      },
      errorMessage: null,
      accountLockedMessage: this.query.lockedOutMessage,
      shake: false,
      passwordToggled: false
    }
  },
  watch: {
    'form.userName': function (newVal, oldVal) {
      if (oldVal === undefined) {
        this.$v.form.userName.$reset()
      }
    },
    'form.password': function (newVal, oldVal) {
      if (oldVal === undefined) {
        this.$v.form.password.$reset()
      }
    }
  },
  methods: {
    async submit () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.accountLockedMessage = ''
        this.errorMessage = ''
        var response = await axios.post('Account/Login', { Email: this.form.userName, Password: this.form.password, ReturnUrl: this.query.returnUrl }, { showload: true })

        if (response.data.errorMessage) {
          this.shakeInputs()
          this.errorMessage = response.data.errorMessage
        } else {
          this.errorMessage = null
          switch (response.data.response) {
            case 'Success':
              location.replace(process.env.VUE_APP_ROOT_API + this.query.returnUrl)
              break
            case 'Verify':
              this.$router.replace({ name: 'VerifyCode', query: { returnUrl: this.query.returnUrl, selectedProvider: response.data.provider } })
              break
            case 'LockedOut':
              this.shakeInputs()
              this.accountLockedMessage = 'Account is Locked, Please try again later'
              break
            case 'LockedOutPerm':
              this.shakeInputs()
              this.accountLockedMessage = 'Account is Locked, due to inactivity. Please contact your system administrator'
              break
            case 'ChangePassword':
              this.$router.replace({ name: 'ChangePassword', query: { code: response.data.code, returnUrl: this.query.returnUrl }, params: { userId: response.data.userId, passwordComplexity: response.data.passwordComplexity, message: response.data.message } })
              break
            case 'Setup2FA':
              this.$router.replace({ name: 'Setup2FA', query: { returnUrl: this.query.returnUrl, selectedProvider: response.data.provider } })
              break
          }
        }
      }
    },
    forgotPassword () {
      this.$router.push({ name: 'ForgotPassword', query: { returnUrl: this.query.returnUrl }, params: { username: this.form.username } })
    },
    shakeInputs () {
      this.shake = true
      setTimeout(() => {
        this.shake = false
      }, 500)
    }

  },
  created () {
    this.errorMessage = this.query.message

    if (this.query.username && this.query.password) {
      this.submit()
    }
  },
  validations () {
    return {
      form: {
        userName: { required },
        password: { required }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@keyframes shake {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-5%);
  }
  30% {
    transform: translateX(5%);
  }
  45% {
    transform: translateX(-3%);
  }
  60% {
    transform: translateX(1%);
  }
  75% {
    transform: translateX(-1%);
  }
  100% {
    transform: translateX(0%);
  }
}

.shake {
  animation-name: shake;
  animation-duration: 0.5s;
}

.fa {
  color: #666666;
}

.input-icon {
  position: absolute;
  top: 16px;
  left: 10px;
}

.form-group {
  position: relative;
}

.input {
  padding-left: 36px;
}

.pt-38 {
  padding-top: 38px;
}
</style>
